<template>
  <nav class="navbar navbar-expand-md navbar-scrollUp navbar-sticky navbar-white">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img class="d-inline-block" src="../assets/img/logo-kc.png" alt="logo-kc" />
      </a>
      <button
        class="navbar-toggler py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fa fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-lg-auto">
          <li class="nav-item dropdown bg-primary">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-exclamation nav-icon" aria-hidden="false"></i>
              <span>關於卓越盃</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/About/News" @click.native="Close"
                  >最新消息</router-link
                >
              </li>

              <li>
                <router-link
                  class="dropdown-item"
                  to="/About/Origin"
                  @click.native="Close"
                  >卓越盃源起</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/About/Feature"
                  @click.native="Close"
                  >競賽特色 <i class="fa fa-chevron-right" aria-hidden="true"></i
                ></router-link>
                <ul class="sub-menu">
                  <li>
                    <router-link to="/About/Feature" @click.native="Close"
                      >命題團隊</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/About/Feature/?id=2" @click.native="Close"
                      >報告書介紹</router-link
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/About/Publish"
                  @click.native="Close"
                  >相關出版品</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Talk/Expert" @click.native="Close"
                  >卓越盃大家談
                  <!--i class="fa fa-chevron-right" aria-hidden="true"></i--></router-link
                >
                <!--ul class="sub-menu">
                  <li><router-link to="/Talk/Expert">專家說</router-link></li>
                  <li><router-link to="/Talk/Parent">家長說</router-link></li>
                </ul-->
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown mega-dropdown bg-danger">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-clock nav-icon" aria-hidden="true"></i>
              <span>競賽須知</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/Schedule"
                  @click.native="Close"
                  >考試時程</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/Process"
                  @click.native="Close"
                  >報名流程</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/Score"
                  @click.native="Close"
                  >作答計分方式</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Notice/Fee" @click.native="Close"
                  >報名費用</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/AwardSingle"
                  @click.native="Close"
                  >獎勵辦法</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/ScopeMath1"
                  @click.native="Close"
                  >命題範圍</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Notice/Rule" @click.native="Close"
                  >應試規則</router-link
                >
              </li>
              <!-- <li v-if="openExam == 'true'"> -->
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/ExamRoom"
                  @click.native="Close"
                  >考場</router-link
                >
              </li>
              <!-- <li v-if="openExam == 'true'"> -->
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Notice/Registration"
                  @click.native="Close"
                  >報名處</router-link
                >
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown bg-success">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-graduation-cap nav-icon" aria-hidden="true"></i>
              <span>考生專區</span>
            </a>
            <ul class="dropdown-menu">
              <li v-if="openBooking == 'true'">
                <router-link
                  class="dropdown-item"
                  to="/Examinee/FormTeam"
                  @click.native="Close"
                  >我要報名</router-link
                >
                <ul class="sub-menu">
                  <li>
                    <router-link
                      to="/Examinee/Privacy?type=Personal"
                      @click.native="Close"
                      >個人報名</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/Examinee/Privacy?type=Group" @click.native="Close"
                      >團體報名</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/Examinee/Team" @click.native="Close"
                      >我要組隊</router-link
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Examinee/OrderTrack"
                  @click.native="Close"
                  >訂單查詢</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Examinee/DataManage"
                  @click.native="Close"
                  >考生資料管理</router-link
                >
              </li>

              <li v-if="isGradePublicOpen == 'true' || isGradeOpen == 'true'">
                <router-link
                  class="dropdown-item"
                  to="/HistoryGrade/Search"
                  @click.native="Close"
                  >{{ newYear }}年成績公告及查詢</router-link
                >
              </li>
              <li v-if="isTitleBookDownloadOpen == 'true'">
                <router-link
                  class="dropdown-item"
                  to="/Examinee/QADownLoad"
                  @click.native="Close"
                  >{{ newYear }}年試題及解答下載</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Examinee/InquiryOverYears"
                  @click.native="Close"
                  >歷年成績及得獎查詢</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Examinee/Reissuance"
                  @click.native="Close"
                  >成績報告書及獎狀補發</router-link
                >
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown bg-danger">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-play nav-icon" aria-hidden="true"></i>
              <span>線上課程專區</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/Sprint/Math" @click.native="Close"
                  >數學衝刺包</router-link
                >
              </li>
              <!-- <li>
                <router-link class="dropdown-item" to="/Sprint" @click.native="Close"
                  >課程介紹</router-link
                >
              </li> -->
              <!-- <li>
                <router-link
                  class="dropdown-item"
                  to="/Sprint/Chapter"
                  @click.native="Close"
                  >課程章節</router-link
                >
              </li> -->
              <li>
                <router-link class="dropdown-item" to="/Sprint/Read" @click.native="Close"
                  >閱讀衝刺包</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Sprint/Strategy"
                  @click.native="Close"
                  >考後真人解題攻略</router-link
                >
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown bg-purple">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-clipboard-list nav-icon" aria-hidden="true"></i>
              <span>菁英榜</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Elite/Personal"
                  @click.native="Close"
                  >個人</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Elite/Group" @click.native="Close"
                  >團體</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/Elite/Statistics"
                  @click.native="Close"
                  >歷年各年級分數統計</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown bg-info">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-comments nav-icon" aria-hidden="true"></i>
              <span>常見問題</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link
                  class="dropdown-item"
                  to="/FAQ/EventInfo"
                  @click.native="Close"
                  >賽事資訊</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/FAQ/ExamContent"
                  @click.native="Close"
                  >考試內容</router-link
                >
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown bg-success">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="isCampOpen == 'true'"
            >
              <i class="fas fa-hourglass-half nav-icon" aria-hidden="true"></i>
              <span>資優數學營</span>
            </a>
            <ul class="dropdown-menu" v-if="isCampOpen == 'true'">
              <li>
                <router-link class="dropdown-item" to="/Camp" @click.native="Close"
                  >營隊介紹</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Camp/Step" @click.native="Close"
                  >營隊報名</router-link
                >
              </li>
              <li v-if="isCampApplyOpen == 'true'">
                <router-link class="dropdown-item" to="/Camp/Search" @click.native="Close"
                  >營隊查詢</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/Camp/Faq" @click.native="Close"
                  >常見問題</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import $ from "jquery";
export default {
  name: "NavBar",
  data() {
    return {
      newYear: 2024, // new Date().getFullYear(),
      openBooking: false,
      openExam: false,
      isCampOpen: false,
      isCampApplyOpen: false,
      isTitleBookDownloadOpen: false,
      isGradeQueryOpen: false,
      isGradePublicOpen: false,
      isGradeOpen: false,
      apiYearData: (data) => this.userRequest.post("KC/YearData", data),
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.memberApplyTimeOver();
      this.getExamIsOpen();

      await this.getGradeIsOpen();
      await this.getGradePublicIsOpen();
      await this.getPublicYear();
      await this.getTitleBookDownloadIsOpen();
      await this.getCampIsOpen();
      await this.getCampApplyIsOpen();
    },
    Close() {
      if ($(".navbar-toggler").is(":visible")) {
        $(".navbar-toggler").click();
      }
    },
    memberApplyTimeOver() {
      let json = {
        TYPE: "CHECK_APPLY_OVER",
      };
      this.FunctionToken(this.ApplyTimeOver, json);
    },
    ApplyTimeOver(data) {
      this.apiYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.openBooking = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExamIsOpen() {
      let json = {
        TYPE: "FRONT_EXAMROOM_ISOPEN",
      };
      this.FunctionToken(this.ExamIsOpen, json);
    },
    ExamIsOpen(data) {
      this.apiYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          console.log("jsonRData", jsonRdata);
          if (jsonRdata.Status) {
            this.openExam = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getGradeIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_GRADE_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/StudentHisGrade", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isGradeOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getGradePublicIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_PUBLIC_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/StudentHisGrade", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isGradePublicOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPublicYear() {
      try {
        let dataJSON = {
          TYPE: "FRONT_PUBLIC_YEAR",
        };

        let resDATA = await this.userRequest.post("KC/StudentHisGrade", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.newYear = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getTitleBookDownloadIsOpen() {
      try {
        let dataTitleBookDownload = {
          TYPE: "FRONT_TITLEBOOKDOWNLOAD_ISOPEN",
        };

        let resTitleBookDownload = await this.userRequest.post(
          "KC/YearData",
          dataTitleBookDownload
        );
        let jsonTitleBookDownload = JSON.parse(resTitleBookDownload.data);
        if (jsonTitleBookDownload.Status) {
          this.isTitleBookDownloadOpen = jsonTitleBookDownload.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_CAMP_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/CampYearData", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampApplyIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_APPLY_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/CampYearData", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampApplyOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
